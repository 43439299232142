/* eslint-disable no-console */
import axios from "axios";
import { searchParamsObject, buildURLParameters } from "@/utils/general";
import { paths } from "@/api/index";

type GetSICCouncil = paths["/api/reporting/sic-council"]["get"];
export type SICCouncil =
  GetSICCouncil["responses"]["200"]["content"]["application/json"];
export const getSICCouncil = async (): Promise<SICCouncil> => {
  const response = await axios.get("/api/reporting/sic-council");
  return response.data;
};

type GetMSLPortfolioCommunication = paths["/api/reporting/msl-portfolio-management"]["get"];
export type MSLPortfolioCommunication = GetMSLPortfolioCommunication["responses"]["200"]["content"]["application/json"];
export const getMSLPortfolioCommunication = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<MSLPortfolioCommunication> => {
  const params = searchParamsObject({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get("/api/reporting/msl-portfolio-management", { params });
  return response.data;
};

type GetIHEPortfolioChanges = paths["/api/reporting/ihe-portfolio"]["get"];
export type IHEPortfolioChanges = GetIHEPortfolioChanges["responses"]["200"]["content"]["application/json"];
export const getIHEPortfolioChanges = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<IHEPortfolioChanges> => {
  const params = searchParamsObject({
    limit,
    marker,
    "sort[]": sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get("/api/reporting/ihe-portfolio", { params });
  return response.data;
};

type GetOpportunities = paths["/api/reporting/opportunities"]["get"];
export type Opportunities =
  GetOpportunities["responses"]["200"]["content"]["application/json"];
export const getOpportunities = async (
  listId: number | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<IHEPortfolioChanges> => {
  const params = searchParamsObject({
    listId,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get("/api/reporting/opportunities", { params });
  return response.data;
};

type AuditLPPdfs = paths["/api/reporting/lp-report"]["post"];
export type AuditLPPdfsBody = AuditLPPdfs["requestBody"]["content"]["multipart/form-data"];
export type AuditLPPdfsResponse = AuditLPPdfs["responses"]["200"]["content"]["application/json"];
export const auditLPPdfs = async (
  pdfFile,
  excelFile,
  partitionPdfBy,
): Promise<void> => {
  const formData = new FormData();
  formData.append("pdf_file", pdfFile);
  formData.append("excel_file", excelFile);
  formData.append("split_by", partitionPdfBy);
  const response = await axios.post("/api/reporting/lp-report", formData, {
    responseType: "blob",
  });

  const filename = response.headers["content-disposition"]
    .split(";")
    .find((x) => x.includes("filename"))
    .replace("filename=", "")
    .replace(/"/g, "")
    .trim();

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
type getActiveVSVDealsWeeklyMeeting =
  paths["/api/reporting/weekly-vsv-deals"]["get"];
export type ActiveVSVDeals =
  getActiveVSVDealsWeeklyMeeting["responses"]["200"]["content"]["application/json"];
export const getActiveVSVDealsWeeklyMeeting = async (
  status,
  announcementStatuses,
  iCDate,
  closeDate,
): Promise<ActiveVSVDeals> => {
  const params = buildURLParameters({
    status,
    announcementStatuses,
    iCDate,
    closeDate,
  });
  const response = await axios.get(`/api/reporting/weekly-vsv-deals?${params}`);
  return response.data;
};

type getActiveGrowthDealsWeeklyMeeting =
  paths["/api/reporting/weekly-growth-deals"]["get"];
export type ActiveGrowthDeals =
  getActiveGrowthDealsWeeklyMeeting["responses"]["200"]["content"]["application/json"];
export const getActiveGrowthDealsWeeklyMeeting = async (
  status,
  statusChangeTime,
  nextStep,
  announcementStatuses,
  closeDate,
): Promise<ActiveGrowthDeals> => {
  const params = buildURLParameters({
    status,
    statusChangeTime,
    nextStep,
    announcementStatuses,
    closeDate,
  });
  const response = await axios.get(
    `/api/reporting/weekly-growth-deals?${params}`,
  );
  return response.data;
};

type GetVSVPipelineReport = paths["/api/reporting/vsv-pipeline"]["get"];
export type VSVPipelineReport =
  GetVSVPipelineReport["responses"]["200"]["content"]["application/json"];
export const getVSVPipelineData = async (
  startDate,
): Promise<VSVPipelineReport> => {
  const params = buildURLParameters({
    startDate,
  });
  const response = await axios.get(`/api/reporting/vsv-pipeline?${params}`);
  return response.data;
};

type GetGrowthPipelineReport = paths["/api/reporting/growth-pipeline"]["get"];
export type GrowthPipelineReport =
  GetGrowthPipelineReport["responses"]["200"]["content"]["application/json"];
export const getGrowthPipelineData = async (
  startDate,
  tiers,
  statuses,
  notNextCheck,
  immediateStatusOnly = false,
  allowNullTiers = false,

): Promise<GrowthPipelineReport> => {
  const params = buildURLParameters({
    startDate,
    tiers,
    statuses,
    notNextCheck,
    immediateStatusOnly,
    allowNullTiers,
  });
  const response = await axios.get(`/api/reporting/growth-pipeline?${params}`);
  return response.data;
};

type GetGrowthDealsVelocityReport = paths["/api/reporting/growth-deals-velocity"]["get"];
export type GrowthDealsVelocityReport =
  GetGrowthDealsVelocityReport["responses"]["200"]["content"]["application/json"];
export const getGrowthDealsVelocityData = async (
  startDate,
  notDealTypes,
  priorStatuses,
): Promise<GrowthDealsVelocityReport> => {
  const params = buildURLParameters({
    startDate,
    notDealTypes,
    priorStatuses,
  });
  const response = await axios.get(
    `/api/reporting/growth-deals-velocity?${params}`,
  );
  return response.data;
};

type GetGrowthDealsReport = paths["/api/reporting/growth-deals"]["get"];
export type GrowthDealsReport =
  GetGrowthDealsReport["responses"]["200"]["content"]["application/json"];
export const getGrowthDealsData = async (
  startDate,
  dealTypes,
  currentStatuses,
): Promise<GrowthDealsReport> => {
  const params = buildURLParameters({
    startDate,
    dealTypes,
    currentStatuses,
  });
  const response = await axios.get(
    `/api/reporting/growth-deals?${params}`,
  );
  return response.data;
};

type GetTooltipData = paths["/api/reporting/report-tooltip"]["get"];
export type TooltipData =
  GetTooltipData["responses"]["200"]["content"]["application/json"];
export const getTooltipData = async (
  valorIds,
): Promise<TooltipData> => {
  const params = buildURLParameters({
    valorIds,
  });
  const response = await axios.get(`/api/reporting/report-tooltip?${params}`);
  return response.data;
};

type GetSignalModelPipeline = paths["/api/reporting/signal-model-pipeline"]["get"];
export type SignalModelPipeline =
  GetSignalModelPipeline["responses"]["200"]["content"]["application/json"];
export const getSignalModelPipeline = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<SignalModelPipeline> => {
  const params = buildURLParameters({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get(`/api/reporting/signal-model-pipeline?${params}`);
  return response.data;
};

type GetSignalModelPipelineExplainability = paths["/api/reporting/signal-model-pipeline/explainability"]["get"];
export type SignalModelPipelineExplainability =
  GetSignalModelPipelineExplainability["responses"]["200"]["content"]["application/json"];
export const getSignalModelPipelineExplainability = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<SignalModelPipelineExplainability> => {
  const params = buildURLParameters({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get(`/api/reporting/signal-model-pipeline/explainability?${params}`);
  return response.data;
};

type GetPortfolioHealth = paths["/api/reporting/fund-health/{fundId}"]["get"];
export type PortfolioHealth = GetPortfolioHealth["responses"]["200"]["content"]["application/json"];
export const getPortfolioHealth = async (fundId: string): Promise<PortfolioHealth> => {
  const response = await axios.get(`/api/reporting/fund-health/${fundId}`);
  return response.data;
};

export const getDealPassReport = async (
  startDate,
  fund,
) => {
  const params = buildURLParameters({
    startDate,
    fund,
  });
  const response = await axios.get(`/api/reporting/deal-passes?${params}`);
  return response.data;
};

export default {
  auditLPPdfs,
  getPortfolioHealth,
  getActiveGrowthDealsWeeklyMeeting,
  getActiveVSVDealsWeeklyMeeting,
  getDealPassReport,
  getGrowthDealsData,
  getGrowthDealsVelocityData,
  getGrowthPipelineData,
  getIHEPortfolioChanges,
  getMSLPortfolioCommunication,
  getSICCouncil,
  getOpportunities,
  getTooltipData,
  getVSVPipelineData,
};
