import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Skeleton,
  Box,
  IconButton,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  getCoreRowModel,
  flexRender,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

export const MTableHead = styled(TableHead)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const MTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(0.75, 0.25),
  overflowWrap: "break-word",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5, 0.125),
  },
}));

export const MHeaderCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(0.75, 0.25),
  overflowWrap: "break-word",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5, 0.125),
  },
}));

export const MTableBody = styled(TableBody)(({ theme }) => ({
  "& > tr:nth-of-type(odd)": {
    background: theme.palette.background.paper,
  },
  "& > tr:nth-of-type(even)": {
    background: theme.palette.background.oddTableRow,
  },
}));

interface MiniTableProps {
  data?: any[];
  columns: any[];
  emptyTable?: string;
  isLoading?: boolean;
  enableSorting?: boolean;
}

export function MiniTable({
  data = [],
  columns,
  isLoading = false,
  emptyTable = "No Data",
  enableSorting = false,
}: MiniTableProps) {
  const theme = useTheme();
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    ...(enableSorting && { getSortedRowModel: getSortedRowModel() }),
  });

  const renderCell = (cell) => {
    const v = cell.getValue();
    if (!v) {
      return <div style={{ width: "100%", textAlign: "center" }}>---</div>;
    }
    const val = flexRender(cell.column.columnDef.cell, cell.getContext());
    return val;
  };

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" sx={{ gap: "4px" }}>
        <Skeleton variant="rectangular" width="100%" height="64px" />
        <Skeleton variant="rectangular" width="100%" height="44px" />
        <Skeleton variant="rectangular" width="100%" height="44px" />
        <Skeleton variant="rectangular" width="100%" height="44px" />
        <Skeleton variant="rectangular" width="100%" height="44px" />
      </Box>
    );
  }

  return (
    <>
      <Table sx={{ overflowX: "scroll" }}>
        <MTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} sx={{ height: theme.spacing(3) }}>
              {headerGroup.headers.map((header) => (
                <MHeaderCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                        style: {
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="boldSubtitle2"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Typography>
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <ArrowDownwardIcon fontSize="small" />
                        ) : (
                          <ArrowUpwardIcon fontSize="small" />
                        )
                      ) : (
                        <UnfoldMoreIcon
                          fontSize="small"
                          sx={{
                            visibility: "hidden",
                            "&:hover": {
                              visibility: "visible",
                            },
                          }}
                        />
                      )}
                    </div>
                  )}
                </MHeaderCell>
              ))}
            </TableRow>
          ))}
        </MTableHead>
        <MTableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow sx={{ height: theme.spacing(3) }} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <MTableCell key={cell.id}>
                  <Typography
                    variant="overline"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "wrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {renderCell(cell)}
                  </Typography>
                </MTableCell>
              ))}
            </TableRow>
          ))}
        </MTableBody>
      </Table>
      {!data?.length ? (
        <Box
          sx={{
            width: "100%",
            height: "108px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">{emptyTable}</Typography>
        </Box>
      ) : null}
    </>
  );
}

export default MiniTable;
