/* eslint-disable no-console, camelcase */
import axios from "axios";

export const getAllTasks = async (user_id) => {
  try {
    const response = await axios.get("/api/tasks/all", {
      params: {
        user_id,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getNumTasks = async (user_id) => {
  if (user_id) {
    try {
      const response = await axios.get("/api/tasks/num", {
        params: {
          user_id,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  }
  return null;
};

export const completeTask = async (task_id) => {
  try {
    const response = await axios.post(`/api/tasks/complete/${task_id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getEvents = async () => {
  const response = await axios.get("/api/tasks/events");
  return response.data;
};

export default {
  getAllTasks,
};
