import React, { useCallback, useEffect } from "react";
import {
  Box, useMediaQuery, Fab, Tooltip,
} from "@mui/material";
import { TaskList, Process } from "@/api/Process";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import {
  VirtualTableContainer,
  VirtualTableBody,
  TableRow as VirtualTableRow,
} from "@/components/InfiniteTable";
import HeaderCell from "@/components/Table/Header/Cell";
import {
  useReactTable,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  VisibilityState,
  SortingState,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { StringParam, useQueryParam } from "use-query-params";

import { TextFilter } from "@/components/Table/Filter";
import DateRange from "@/components/Table/CustomFilters/DateRange";
import NumberRange from "@/components/Table/CustomFilters/NumberRange";
import { SelectFilter } from "@/components/Table/CustomFilters/Select";
import BooleanFilter from "@/components/Table/CustomFilters/Boolean";
import DateCell from "@/components/Table/Primitives/DateCell";
import TableToolbar from "@/components/Table/Header/Toolbar";
import DynamicSelectFilter from "@/components/Table/CustomFilters/DynamicSelectFilter";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import ProcessTrackerCell from "./Cell";
import SavedViews from "./SavedViews";

import {
  FILTER_TYPE_MAP as FilterTypeMap,
  SORT_TYPE_MAP as SortTypeMap,
  FIELD_NAME_TYPE_MAP as fieldNameMap,
} from "../constants";

export default function TableView({
  isLoading,
  fields,
  processId,
  tasks,
  // fieldFilters,
  views,
  // entityName,
  showTask,
  isFetching,
  fetchNextPage,
  sorting,
  columnFilters,
  columnVisibility,
  columnOrder,
  onSortingChange,
  onColumnFilterChange,
  onColumnVisibilityChange,
  onColumnOrderChange,
  getFilterCounts,
  enableSorting = true,
  enableColumnFilter = true,
  enableViews = true,
  readOnly = false,
}: {
  isLoading: boolean;
  fields: Process["fields"];
  processId: string;
  tasks: TaskList["tasks"];
  // fieldFilters: Process["fieldFilters"];
  views: Process["views"];
  // entityName?: string;
  isFetching: boolean;
  fetchNextPage: () => void;
  getFilterCounts: (string) => any;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
  columnVisibility: VisibilityState;
  columnOrder: string[];
  showTask: (pId: string, taskId: string) => void;
  onSortingChange: (value: SortingState) => void;
  onColumnFilterChange: (value: ColumnFiltersState) => void;
  onColumnVisibilityChange: (value: VisibilityState) => void;
  onColumnOrderChange: (value: string[]) => void;
  enableSorting?: boolean;
  enableColumnFilter?: boolean;
  enableViews?: boolean;
  readOnly?: boolean;
}) {
  const [activeViewId, setActiveViewId] = useQueryParam(
    "activeView",
    StringParam,
  );
  const [, setGlobalFilter] = React.useState("");
  const [hasManualChanges, setHasManualChanges] = React.useState(false);
  const [fullScreenTable, setFullScreenTable] = React.useState(false);

  // get field from values
  const getFieldValue = (entity, field) => {
    const fieldId = field.id;
    if (!entity.fieldValues?.[fieldId]) {
      return "";
    }

    if (field.type === "select") {
      const choice = field.choices.find(
        (c) => c.id === entity.fieldValues[fieldId].choiceId,
      );
      return choice?.value;
    }

    if (field.type === "select_multi") {
      return entity.fieldValues[fieldId].choices?.map((c) => {
        const choice = field.choices?.find((ch) => ch.id === c);
        return choice?.value;
      });
    }

    return entity.fieldValues[fieldId] ?? undefined;
  };

  const FilterComponents = React.useMemo(
    () => ({
      text: (props) => (
        <TextFilter
          header={props.field.name}
          getFilterValue={props.getFilterValue}
          setFilterValue={props.setFilterValue}
        />
      ),
      date: DateRange,
      number: NumberRange,
      ryg: (props) => (
        <SelectFilter
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          options={[
            { value: "R", label: "R" },
            { value: "Y", label: "Y" },
            { value: "G", label: "G" },
          ]}
          getOptionsLabel={(value) => value.label}
          getOptionsValue={(value) => value.value}
          getFilterCount={props.getFilterCount}
          multiple
        />
      ),
      user: (props) => (
        <DynamicSelectFilter
          columnId={props.columnId}
          fieldName={props.field.name}
          getFilterValue={props.getFilterValue}
          setFilterValue={props.setFilterValue}
          multiple
          getFilterCounts={
            getFilterCounts
              ? () => getFilterCounts(props.columnId.split(".")[2])
              : null
          }
        />
      ),
      user_multi: (props) => (
        <DynamicSelectFilter
          columnId={props.columnId}
          fieldName={props.field.name}
          getFilterValue={props.getFilterValue}
          setFilterValue={props.setFilterValue}
          multiple
          getFilterCounts={
            getFilterCounts
              ? () => getFilterCounts(props.columnId.split(".")[2])
              : null
          }
        />
      ),
      person: (props) => (
        <DynamicSelectFilter
          columnId={props.columnId}
          fieldName={props.field.name}
          getFilterValue={props.getFilterValue}
          setFilterValue={props.setFilterValue}
          multiple
          getFilterCounts={
            getFilterCounts
              ? () => getFilterCounts(props.columnId.split(".")[2])
              : null
          }
        />
      ),
      person_multi: (props) => (
        <DynamicSelectFilter
          columnId={props.columnId}
          fieldName={props.field.name}
          getFilterValue={props.getFilterValue}
          setFilterValue={props.setFilterValue}
          multiple
          getFilterCounts={
            getFilterCounts
              ? () => getFilterCounts(props.columnId.split(".")[2])
              : null
          }
        />
      ),
      company: (props) => (
        <DynamicSelectFilter
          columnId={props.columnId}
          fieldName={props.field.name}
          getFilterValue={props.getFilterValue}
          setFilterValue={props.setFilterValue}
          multiple
          getFilterCounts={
            getFilterCounts
              ? () => getFilterCounts(props.columnId.split(".")[2])
              : null
          }
        />
      ),
      select: (props) => (
        <SelectFilter
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          options={props.field.choices.map((choice) => ({
            value: choice.id,
            label: choice.value,
          }))}
          getOptionsLabel={(value) => value.label}
          getOptionsValue={(value) => value.value}
          getFilterCounts={
            getFilterCounts
              ? () => getFilterCounts(props.columnId.split(".")[2])
              : null
          }
          multiple
        />
      ),
      boolean: BooleanFilter,
    }),
    [getFilterCounts],
  );
  // build column defs using fields
  const columnDef = React.useMemo(() => {
    // get primary company field
    const companyField = fields.find(
      (f) => f.type === "company" && f.isPrimary,
    );

    const columns = fields.map((field) => ({
      id: `tasks.fieldValues.${field.id}.${
        fieldNameMap[field.type] || "value"
      }`,
      header: field.name,
      accessorFn: (row) => getFieldValue(row, field),
      // ⢀⡴⠑⡄⠀⠀⠀⠀⠀⠀⠀⣀⣀⣤⣤⣤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
      // ⠸⡇⠀⠿⡀⠀⠀⠀⣀⡴⢿⣿⣿⣿⣿⣿⣿⣿⣷⣦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠑⢄⣠⠾⠁⣀⣄⡈⠙⣿⣿⣿⣿⣿⣿⣿⣿⣆⠀⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⢀⡀⠁⠀⠀⠈⠙⠛⠂⠈⣿⣿⣿⣿⣿⠿⡿⢿⣆⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⢀⡾⣁⣀⠀⠴⠂⠙⣗⡀⠀⢻⣿⣿⠭⢤⣴⣦⣤⣹⠀⠀⠀⢀⢴⣶⣆
      // ⠀⠀⢀⣾⣿⣿⣿⣷⣮⣽⣾⣿⣥⣴⣿⣿⡿⢂⠔⢚⡿⢿⣿⣦⣴⣾⠁⠸⣼⡿
      // ⠀⢀⡞⠁⠙⠻⠿⠟⠉⠀⠛⢹⣿⣿⣿⣿⣿⣌⢤⣼⣿⣾⣿⡟⠉⠀⠀⠀⠀⠀
      // ⠀⣾⣷⣶⠇⠀⠀⣤⣄⣀⡀⠈⠻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠀⠀⠀⠀⠀⠀
      // ⠀⠉⠈⠉⠀⠀⢦⡈⢻⣿⣿⣿⣶⣶⣶⣶⣤⣽⡹⣿⣿⣿⣿⡇⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠀⠀⠀⠉⠲⣽⡻⢿⣿⣿⣿⣿⣿⣿⣷⣜⣿⣿⣿⡇⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⣷⣶⣮⣭⣽⣿⣿⣿⣿⣿⣿⣿⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠀⠀⣀⣀⣈⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠇⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠀⠀⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠃⠀⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠀⠀⠀⠹⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠟⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀
      // ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠻⠿⠿⠿⠿⠛⠉
      // its only temporary.
      size:
        field.name === "Most Recent Comment" || field.name === "Notes"
          ? 500
          : field.name === "Name"
              || (field.name === "Stage" && field.type === "select")
            ? 200
            : 160,
      cell: (prop) => (
        <ProcessTrackerCell
          processId={processId}
          taskId={prop.row.original.id}
          taskInternalId={prop.row.original.internalId}
          primaryCompanyValorId={
            prop.row.original.fieldValues[companyField?.id]?.company?.valorId
          }
          field={field}
          value={prop.getValue()}
          isLoading={
            field.type === "company" && field.isPrimary
              ? false
              : prop.row.original.fieldValuesLoading
          }
        />
      ),
      isOrganizationField: field.isOrganizationField,
      sortUndefined: "last",
      // sortingFn: SortingFunctions[field.type]?.(field),
      // filterFn: FilterFunctions[FilterTypeMap[field.type]],
      filter: FilterComponents[FilterTypeMap[field.type]],
      meta: {
        field,
        sortType: SortTypeMap[field.type],
      },
      enableSorting:
        field.sortable && enableSorting && !field.type.includes("multi"),
      enableColumnFilter:
        enableColumnFilter
        && field.type !== "latest_note"
        && !field.type.endsWith("_json"),
    }));

    const sorted = columns.toSorted((a, b) => {
      if (a.type === "company" && a.isPrimary) {
        return -1;
      }
      if (b.type === "company" && b.isPrimary) {
        return 1;
      }
      return a.sort - b.sort;
    });

    if (sorted.length === 0) {
      return [];
    }

    return [
      ...sorted,
      {
        id: "dateAdded",
        header: "Date Added",
        accessorFn: (row) => row.createdAt,
        cell: (prop) => <DateCell value={prop.getValue()} />,
        enableSorting,
        enableColumnFilter,
        filter: FilterComponents.date,
        meta: {
          sortType: "date",
        },
      },
    ];
  }, [fields, enableSorting, enableColumnFilter, FilterComponents, processId]);

  const processViewVisibility = useCallback(
    (vis) => {
      const allCols = columnDef.map((col) => col.id);
      const modifiedVisibility = { ...vis };
      for (let i = 0; i < allCols.length; i += 1) {
        if (vis[allCols[i]] === undefined) {
          modifiedVisibility[allCols[i]] = false;
        }
      }
      return modifiedVisibility;
    },
    [columnDef],
  );

  const handleSortingChange = useCallback(
    (newSorting) => {
      setHasManualChanges(true);
      onSortingChange(newSorting);
    },
    [onSortingChange, setHasManualChanges],
  );

  const handleColumnFiltersChange = useCallback(
    (newFilters) => {
      setHasManualChanges(true);
      onColumnFilterChange(newFilters);
    },
    [onColumnFilterChange, setHasManualChanges],
  );

  const handleColumnVisibilityChange = useCallback(
    (newVisibility) => {
      setHasManualChanges(true);
      onColumnVisibilityChange(newVisibility);
    },
    [onColumnVisibilityChange, setHasManualChanges],
  );

  const handleColumnOrderChange = useCallback(
    (newOrder) => {
      setHasManualChanges(true);
      onColumnOrderChange(newOrder);
    },
    [onColumnOrderChange, setHasManualChanges],
  );

  const table = useReactTable({
    data: tasks,
    columns: columnDef,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      columnOrder,
    },
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnFiltersChange: handleColumnFiltersChange,
    onColumnOrderChange: handleColumnOrderChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    // getSortedRowModel: getSortedRowModel(), // client-side sorting
    // getFilteredRowModel: getFilteredRowModel(), // client-side filtering
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const selectView = (viewId) => {
    setHasManualChanges(false);
    if (!viewId) {
      onSortingChange([]);
      onColumnFilterChange([]);
      onColumnVisibilityChange([]);
      onColumnOrderChange([]);
    }
    setActiveViewId(viewId);

    if (viewId === "") {
      onSortingChange([]);
      onColumnFilterChange([]);
      onColumnVisibilityChange({});
      onColumnOrderChange([]);
    }
  };

  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  let tableSpacing = "300px";
  if (!enableViews) {
    tableSpacing = "300px";
    if (isLgUp) {
      tableSpacing = "220px";
    }
  }
  if (isLgUp) {
    tableSpacing = "290px";
  }

  useEffect(() => {
    const handleViewId = (viewId) => {
      if (!hasManualChanges) {
        const view = views.find((v) => v.id === viewId);
        if (view) {
          if (view.sorting) onSortingChange(view.sorting);
          if (view.columnFilters) onColumnFilterChange(view.columnFilters);
          if (view.columnVisibility) {
            onColumnVisibilityChange(
              processViewVisibility(view.columnVisibility),
            );
          }
          if (view.columnOrder) onColumnOrderChange(view.columnOrder);
        }
      }
    };

    if (views && activeViewId) {
      handleViewId(activeViewId);
    }
  }, [
    views,
    activeViewId,
    hasManualChanges,
    onSortingChange,
    onColumnFilterChange,
    onColumnVisibilityChange,
    onColumnOrderChange,
    processViewVisibility,
  ]);

  return (
    <Box>
      <Box
        sx={(theme) => ({
          borderTop: `2px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          px: 1,
        })}
      >
        {enableViews && (
          <SavedViews
            views={views}
            value={activeViewId}
            onChange={(e) => {
              const viewId = e.target.value;
              selectView(viewId);
            }}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TableToolbar
            columnHeaders={columnDef}
            sorting={sorting}
            columnVisibility={columnVisibility}
            columnFilters={columnFilters}
            onRemoveColumnFilters={() => {
              handleColumnFiltersChange([]);
            }}
            onRemoveSorting={() => {
              handleSortingChange([]);
            }}
            onRemoveVisibility={() => {
              handleColumnVisibilityChange({});
            }}
            isLoading={isLoading || isFetching}
            readOnly={readOnly}
          />
        </Box>
      </Box>

      <VirtualTableContainer
        height={`calc(100vh - ${tableSpacing})`}
        minHeight="500px"
        onScrollBottomReached={() => {
          fetchNextPage?.();
        }}
        fullScreen={fullScreenTable}
      >
        <StickyTable>
          <StickyTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <VirtualTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <HeaderCell
                    key={header.id}
                    header={header}
                    column={header.column}
                    table={table}
                    shrink
                  />
                ))}
              </VirtualTableRow>
            ))}
          </StickyTableHead>
          <VirtualTableBody
            rows={table.getRowModel().rows}
            estimateSize={50}
            onRowDoubleClick={(row) => {
              showTask?.(row.original.processId, row.original.id);
            }}
            table={table}
          />
        </StickyTable>
      </VirtualTableContainer>

      <Tooltip title="Toggle Table Fullscreen">
        <Fab
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 20_000_000,
          }}
          color="primary"
          onClick={() => {
            setFullScreenTable((prev) => !prev);
          }}
        >
          {fullScreenTable ? <FullscreenExit /> : <Fullscreen />}
        </Fab>
      </Tooltip>
    </Box>
  );
}
