import React, { useEffect, useCallback } from "react";

import { Box } from "@mui/material";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import {
  getConversation,
  updateConversation,
  deleteConversation,
} from "@/api/Chat";
import Conversation from "./Conversation";
import ChatBox from "./ChatBox";
import ChatHeader from "./ChatHeader";
import useChat, {
  CHAT_ERROR,
  CHAT_RESPONSE_CANCELLED,
  CHAT_RESPONSE_CHUNK,
  CHAT_RESPONSE_COMPLETE,
  STREAM_CANCELLED,
} from "../useChat";

export default function Chat() {
  const { id: conversationId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();

  const {
    joinConversation,
    leaveConversation,
    stopStream,
    sendMessage,
    subscribe,
    unsubscribe,
  } = useChat();

  const [disabled, setDisabled] = React.useState(true);
  const [chatDisabled, setChatDisabled] = React.useState(false);
  const [chatLoading, setChatLoading] = React.useState(true);

  const [conversation, setConversation] = React.useState({
    conversation: null,
    messages: [],
  });
  const addChunk = useCallback(
    (id, chunk) => {
      // check if last message is from the same user
      setConversation((prev) => {
        if (!prev?.messages) return prev?.messages;
        const lastMessage = prev.messages[prev.messages.length - 1];
        if (lastMessage.type === "assistant") {
          return {
            ...prev,
            messages: [
              ...prev.messages.slice(0, prev.messages.length - 1),
              {
                ...lastMessage,
                stream: [...lastMessage.stream, chunk],
              },
            ],
          };
        }
        return {
          ...prev,
          messages: [
            ...prev.messages,
            {
              id: crypto.randomUUID(),
              type: "assistant",
              stream: [chunk],
            },
          ],
        };
      });
    },
    [setConversation]
  );

  const addReply = useCallback(
    (id, reply) => {
      setConversation((prev) => ({
        ...prev,
        messages: [
          ...prev.messages,
          {
            id: crypto.randomUUID(),
            role: "user",
            content: reply,
          },
        ],
      }));
    },
    [setConversation]
  );

  const handleSend = (message) => {
    sendMessage(conversationId, message);

    addReply(conversationId, message);
  };

  const handleStop = () => {
    stopStream(conversationId);
  };

  useEffect(() => {
    if (!conversationId) {
      const currentSearchParams = searchParams.toString();
      const queryString = currentSearchParams ? `?${currentSearchParams}` : "";
      if (queryString) {
        navigate(`/grok/chat/${crypto.randomUUID()}${queryString}`, {
          replace: true,
        });
      } else {
        navigate(`/grok/chat/${crypto.randomUUID()}`, { replace: true });
      }
    } else {
      // Reset loading state and fetch new conversation
      setChatLoading(true);
      setDisabled(true);

      getConversation(conversationId)
        .then((data) => {
          setConversation(data);
          setChatLoading(false);
          setDisabled(false);
        })
        .catch((error) => {
          console.error("Failed to fetch conversation:", error);
          setChatLoading(false);
          setDisabled(false);
        });
    }
  }, [conversationId, navigate, searchParams]); // Remove conversation from dependencies

  // useEffect(() => {
  //   if (!conversationId) {
  //     const currentSearchParams = searchParams.toString();
  //     const queryString = currentSearchParams ? `?${currentSearchParams}` : "";
  //     if (queryString) {
  //       navigate(`/grok/chat/${crypto.randomUUID()}${queryString}`, {
  //         replace: true,
  //       });
  //     } else {
  //       navigate(`/grok/chat/${crypto.randomUUID()}`, { replace: true });
  //     }
  //   } else if (!conversation.messages?.length) {
  //     // fetch conversation
  //     getConversation(conversationId).then((data) => {
  //       if (!conversation.messages?.length) {
  //         setConversation(data);
  //       }
  //       setChatLoading(false);
  //       setDisabled(false);
  //     });
  //   }
  // }, [conversationId, navigate, searchParams, conversation]);

  useEffect(() => {
    if (!conversationId) {
      return () => {};
    }

    joinConversation(conversationId);

    return () => {
      leaveConversation(conversationId);
    };
  }, [conversationId, joinConversation, leaveConversation]);

  useEffect(() => {
    if (!conversationId) {
      return;
    }
    // pull "question" and start chat
    const question = searchParams.get("question");
    if (question && conversationId) {
      sendMessage(conversationId, question);
      // remove question from search params
      addReply(conversationId, question);
      navigate(`/grok/chat/${conversationId}`, { replace: true });
      setChatLoading(false);
      setDisabled(false);
    }
  }, [conversationId, addReply, user.id, navigate, searchParams, sendMessage]);

  const reenableChat = useCallback(() => {
    setChatDisabled(false);
  }, [setChatDisabled]);
  const handleChatResponseChunk = useCallback(
    (data) => {
      setChatDisabled(true);
      addChunk(conversationId, data);
    },
    [addChunk, conversationId]
  );

  useEffect(() => {
    subscribe(CHAT_RESPONSE_CHUNK, handleChatResponseChunk);
    subscribe(CHAT_ERROR, reenableChat);
    subscribe(CHAT_RESPONSE_COMPLETE, reenableChat);
    subscribe(STREAM_CANCELLED, reenableChat);
    subscribe(CHAT_RESPONSE_CANCELLED, reenableChat);

    return () => {
      unsubscribe(CHAT_RESPONSE_CHUNK);
      unsubscribe(CHAT_ERROR);
      unsubscribe(CHAT_RESPONSE_COMPLETE);
      unsubscribe(STREAM_CANCELLED);
      unsubscribe(CHAT_RESPONSE_CANCELLED);
    };
  }, [subscribe, unsubscribe, reenableChat, handleChatResponseChunk]);

  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      display="flex"
      flexDirection="column"
      paddingTop={{
        xs: 0,
        sm: 4,
      }}
    >
      <ChatHeader
        title={conversation.conversation?.title}
        disabled={disabled}
        onRename={(title) => {
          setConversation((prev) => ({
            ...prev,
            conversation: {
              ...prev.conversation,
              title,
            },
          }));
          return updateConversation(conversationId, {
            title,
          });
        }}
        onDelete={() => {
          navigate("/grok");
          return deleteConversation(conversationId);
        }}
      />
      <Conversation conversation={conversation} loading={chatLoading} />
      <ChatBox
        onSend={handleSend}
        onStop={handleStop}
        disabled={chatDisabled}
      />
    </Box>
  );
}
