/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import Helmet from "react-helmet";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Page from "@/ui/molecules/Page";
import { canSeeAllTasks } from "@/constants/Roles";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  makeStyles,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import analytics from "@/shared/analytics";
import { useAuth } from "@/hooks/useAuth";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import useLandscape from "@/hooks/useLandscape";
import AllTasks from "./AllTasks";
import UserTasks from "./UserTasks";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

function TasksPage() {
  const classes = useStyles();
  const { user } = useAuth();
  const [page, setPage] = useState("user_tasks_overdue");

  const exportToExcel = useRef(null);
  const isinTouchPWA = useIsInTouchPWA();
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isLandscape = useLandscape();

  return (
    <Page>
      <Helmet>
        <title>Tasks - vOS</title>
      </Helmet>
      {canSeeAllTasks(user) && (
        <Box className={classes.header}>
          {isMdUp ? (
            <>
              <ButtonTabs
                width={300}
                options={[
                  {
                    key: "user_tasks_overdue",
                    label: "My Tasks",
                    dataCy: "my-tasks",
                  },
                  { key: "all_tasks", label: "All Tasks", dataCy: "all-tasks" },
                ]}
                onClick={(option) => setPage(option.key)}
                activeKey={page}
              />
              <Button
                style={{
                  height: "30px",
                  whiteSpace: "nowrap",
                }}
                color="primary"
                variant="contained"
                startIcon={<GetApp color="inherit" />}
                onClick={() => exportToExcel.current()}
              >
                Export to Excel
              </Button>
            </>
          ) : null}
        </Box>
      )}
      <Page.Content>
        <Box>
          {page === "all_tasks" ? (
            <AllTasks exportToExcel={exportToExcel} />
          ) : (
            <UserTasks
              exportToExcel={exportToExcel}
              userId={user.id}
              department={user.department}
              page={page}
            />
          )}
        </Box>
        {!(isMdUp && !isLandscape) ? (
          <Paper
            style={{
              position: "fixed",
              bottom: isinTouchPWA ? 60 : 0,
              left: 0,
              right: 0,
            }}
          >
            <BottomNavigation
              showLabels
              value={page}
              onChange={(event, newValue) => {
                setPage(newValue);
                analytics
                  .track("MOBILE tasks navigate", { key: newValue });
              }}
            >
              <BottomNavigationAction
                label="Overdue"
                value="user_tasks_overdue"
              />
              <BottomNavigationAction
                label="Upcoming"
                value="user_tasks_upcoming"
              />
              {/* <BottomNavigationAction label="All Tasks" value="all_tasks" /> */}
            </BottomNavigation>
          </Paper>
        ) : null}
      </Page.Content>
    </Page>
  );
}

export default TasksPage;
